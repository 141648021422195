import React, { useState } from "react";
import NavItem from "./NavItem";
import Logo from "../../Logo";
import Hamburger from "../../Hamburger";
import Cross from "../../Cross";

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <nav className="nav">
            <div className="nav__container">
                <div className="nav__logos">
                    <Logo />
                </div>
                <ul className="nav__navigation nav__navigation--desktop">
                    <NavItem href="/#services" text="Diensten"/>
                    <NavItem href="/#about" text="Over mij"/>
                    <NavItem href="/#contact" text="Contact"/>
                </ul>
                { isOpen ? <Cross onClick={ () => setOpen(false) } /> : <Hamburger onClick={ () => setOpen(true) } /> }
                <ul className={`${isOpen && "nav__navigation--mobile-visible"} nav__navigation nav__navigation--mobile`} >
                    <NavItem onClick={ () => setOpen(false) } href="/arbeidsrecht" text="Arbeidsrecht"/>
                    <NavItem onClick={ () => setOpen(false) } href="/mediation" text="Huurrecht"/>
                    <NavItem onClick={ () => setOpen(false) } href="/human-rights" text="Human Rights"/>
                    <NavItem onClick={ () => setOpen(false) } href="/#about" text="Over mij"/>
                    <NavItem onClick={ () => setOpen(false) } href="/#contact" text="Contact"/>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
