import React from "react";
import { Link } from "gatsby";

const Logo = () => (
    <Link to="/" className="logo" title="Straver Legal">
        <p class="logo__name">Straver</p>
        <div className="logo__services">
            <p className="logo__service">legal</p>
            <p className="logo__service">advice</p>
        </div>
    </Link>
);

export default Logo;
