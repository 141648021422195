import React from "react";

const Hamburger = props => (
    <div className="hamburger" onClick={ props.onClick }>
        <div className="hamburger__bar"></div>
        <div className="hamburger__bar"></div>
        <div className="hamburger__bar"></div>
    </div>
);

export default Hamburger;
